import React from "react"
import PageTransition from "gatsby-plugin-page-transitions"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import ArticleOverviewLayout from "../layouts/ArticleOverviewLayout"
import Pager from "../components/Pager"
import GridLayout from "../layouts/GridLayout"
import { navigate } from "gatsby"
import { urlFor } from "../util/content-links"

export default ({
  pageContext: { page, pageCount, locale },
  data: {
    pageData,
    articles: { edges },
    featured,
    labels,
  },
}) => (
  <PageTransition>
    <ArticleOverviewLayout
      articles={edges}
      locale={locale}
      articlesSectionLabel={labels.otherNewsLabel}
      impactful={{
        articles: featured,
        label: labels.featuredNewsTitle,
      }}
    >
      {pageCount > 1 && (
        <GridLayout>
          <Pager
            pages={pageCount}
            current={page ? page - 1 : 0}
            forGridLayout
            onPageClick={page =>
              navigate(
                urlFor(pageData.__typename, locale, page === 0 ? "" : page + 1)
              )
            }
          />
        </GridLayout>
      )}
      <HelmetDatoCms seo={pageData.seoMetaTags} />
    </ArticleOverviewLayout>
  </PageTransition>
)

export const query = graphql`
  fragment newsArticleCard on DatoCmsNewsItem {
    __typename
    title
    slug
    locale
    meta {
      publishedAt
    }
    seo {
      description
      image {
        fluid(
          maxWidth: 650
          imgixParams: { auto: "compress", w: "650", h: "650", fit: "crop" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    author {
      ... on DatoCmsTeamMember {
        name
        isAvatarRound
        image {
          ...SmallAvatar
        }
      }
      ... on DatoCmsExternal {
        name
        isAvatarRound
        image {
          ...SmallAvatar
        }
      }
    }
  }
  query(
    $id: String!
    $locale: String!
    $articles: [String!]!
    $featured: [String!]!
  ) {
    labels: datoCmsTranslation(locale: { eq: $locale }) {
      featuredNewsTitle
      otherNewsLabel
    }
    pageData: datoCmsNewsPage(locale: { eq: $locale }, id: { eq: $id }) {
      __typename
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    featured: allDatoCmsNewsItem(
      filter: {
        locale: { eq: $locale }
        slug: { ne: null }
        originalId: { in: $featured }
      }
    ) {
      edges {
        node {
          ...newsArticleCard
        }
      }
    }
    articles: allDatoCmsNewsItem(
      filter: {
        locale: { eq: $locale }
        slug: { ne: null }
        originalId: { in: $articles }
      }
      sort: { fields: actualPublishDate, order: DESC }
    ) {
      edges {
        node {
          ...newsArticleCard
        }
      }
    }
  }
`
